import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";

class SeeYouSoonPage extends React.Component {
  render() {
    const { data } = this.props;
    const highlightedProgramData = data.highlightedPrograms.edges
      .map(p => p.node.frontmatter)
      .filter(program => program.enabled);
    const hasUpcomingProgram =
      highlightedProgramData.filter(program => !!program.eventbrite_id)
        .length === 1;
    return (
      <Layout>
        <SEO
          title="The Kalb Report | Registration Confirmation"
          keywords={[`kalb`, `kalb report`, `the kalb report`]}
        />
        <section className="small-page-heading">
          <div className="container">
            <h1>We'll see you soon!</h1>
          </div>
        </section>
        <section
          className="about-2 section bg-gray about-the-program-section"
          id="about"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <h3>You're registered to attend our upcoming program.</h3>
                <p>
                  <strong>
                    Check your email inbox shortly for a confirmation email and
                    a PDF attachment with your tickets.
                  </strong>
                </p>
                <br />
                <hr />
                <br />
                {hasUpcomingProgram
                  ? highlightedProgramData.map(highlightedProgram => {
                      return (
                        <div key={highlightedProgram.date.toString()}>
                          <div>
                            {/* {highlightedProgram.photo ? (
                              <Img
                                fluid={
                                  highlightedProgram.photo.childImageSharp.fluid
                                }
                                fadeIn={false}
                                style={{
                                  maxWidth: 439,
                                }}
                              />
                            ) : null} */}
                          </div>
                          <div className="block">
                            <div className="program-promo">
                              <h4 style={{ float: "right" }}>
                                December 4, 2019
                              </h4>
                              <h3>{highlightedProgram.title}</h3>

                              <div
                                className="about-the-program"
                                dangerouslySetInnerHTML={{
                                  __html: highlightedProgram.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
                <hr />
                <h4>Location:</h4>
                <p>
                  The Kalb Report is produced before a live studio audience at
                  the National Press Club, located on the 13th floor of the
                  National Press Building, 14th and F Streets, NW, Washington,
                  D.C.
                </p>
                <hr />
                <h4>Seating:</h4>
                <p>
                  Doors open at 7:00 p.m. Guests are advised to arrive by 7:30
                  p.m. and must be seated by 7:40 p.m. The program begins at
                  8:00 p.m. Seating is first come, first seated.
                </p>
              </div>
              <div className="col-lg-3 offset-lg-1">
                <div className="sidebar-images d-lg-block d-none ">
                  <div className="sidebar-image">
                    <Img
                      fluid={data.dianeSawyer.childImageSharp.fluid}
                      fadeIn={false}
                    />
                  </div>
                  <div className="sidebar-image">
                    <Img
                      fluid={data.tedKoppel.childImageSharp.fluid}
                      fadeIn={false}
                    />
                  </div>
                  <div className="sidebar-image">
                    <Img
                      fluid={data.allTheNews.childImageSharp.fluid}
                      fadeIn={false}
                    />
                  </div>
                  <div className="sidebar-image">
                    <Img
                      fluid={data.onDemand.childImageSharp.fluid}
                      fadeIn={false}
                    />
                  </div>
                  <div className="sidebar-image d-sm-none d-md-block d-xl-none">
                    <Img
                      fluid={data.walterIsaacson.childImageSharp.fluid}
                      fadeIn={false}
                    />
                  </div>
                  <div className="sidebar-image d-sm-none d-md-block d-xl-none">
                    <Img
                      fluid={data.rupertMurdoch.childImageSharp.fluid}
                      fadeIn={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export const sidebarImage = graphql`
  fragment sidebarSeeYouSoonImage on File {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
  query {
    dianeSawyer: file(relativePath: { eq: "diane_sawyer.jpg" }) {
      ...sidebarSeeYouSoonImage
    }

    tedKoppel: file(relativePath: { eq: "ted_koppel.jpg" }) {
      ...sidebarSeeYouSoonImage
    }

    allTheNews: file(relativePath: { eq: "all_the_news.jpg" }) {
      ...sidebarSeeYouSoonImage
    }

    walterIsaacson: file(relativePath: { eq: "walter_isaacson.jpg" }) {
      ...sidebarSeeYouSoonImage
    }

    onDemand: file(relativePath: { eq: "on_demand.jpg" }) {
      ...sidebarSeeYouSoonImage
    }

    rupertMurdoch: file(relativePath: { eq: "rupert_murdoch.jpg" }) {
      ...sidebarSeeYouSoonImage
    }
    aptLogo: file(relativePath: { eq: "apt.svg" }) {
      publicURL
    }
    mptLogo: file(relativePath: { eq: "mpt.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    highlightedPrograms: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/(highlightedPrograms)/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            description
            photo {
              childImageSharp {
                fluid(maxWidth: 439) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            link {
              link_title
              url
            }
            enabled
            eventbrite_id
          }
        }
      }
    }
  }
`;

export default SeeYouSoonPage;
